const BtnToTop = document.querySelector(".ButtonToTop");

window.addEventListener("scroll", pageScrollHandler);
BtnToTop.addEventListener("click", onBtnToTopClick);

// show or hide button depending on location
function pageScrollHandler() {
  const scrollParam = window.scrollY;
  const coords = document.documentElement.clientHeight / 2;

  if (scrollParam > coords) {
    BtnToTop.classList.add("ButtonToTop--show");
  }
  if (scrollParam < coords) {
    BtnToTop.classList.remove("ButtonToTop--show");
  }
}

function onBtnToTopClick() {
  if (window.scrollY > 0) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}

exports.onBtnToTopClick = onBtnToTopClick;
exports.BtnToTop = BtnToTop;
