const btnNavElement = document.body.querySelector("[data-btn-nav]");
btnNavElement.addEventListener("click", onBtnNavMenuClick);

const backdropNavElement = document.body.querySelector("[data-backdrop-nav");
backdropNavElement.addEventListener("click", onBtnNavMenuClick);

const navMenuElement = document.body.querySelector("[data-nav]");

function onBtnNavMenuClick() {
  btnNavElement.setAttribute(
    "aria-expanded",
    !(btnNavElement.getAttribute("aria-expanded") === "true")
  );

  backdropNavElement.classList.toggle("NavBar_Backdrop--show");
  navMenuElement.classList.toggle("NavBar_List--open");
  document.body.classList.toggle("is-noscroll");
}
