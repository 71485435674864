import "./sass/main.scss";
import "./js/btn-to-top";
import "./js/btn-mobile-nav";
import i18nextify from "i18nextify";

i18nextify.init(/*{ debug: true }*/);

//apply "selected" class for current path in Navigation
const currentURL = window.location.pathname;
const navElement = document.querySelector(
  `.NavBar_Element [href="${currentURL}"]`
);
navElement.classList.add("navbar_link--selected");

//update actial date in copyright
const currentYear = new Date().getFullYear();
const copyrightElement = document.querySelector(".Footer_Copyright");
const copyright = copyrightElement.innerHTML;
const startYear = parseInt(copyright.substring(2, 6));
if (currentYear > startYear) {
  //TODO: rewrite this via substring search?
  copyrightElement.innerHTML = `${copyright.substring(
    0,
    6
  )}-${currentYear.toString()} ${copyright.substring(6)}`;
}
